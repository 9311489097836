@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #0f1113;
  /* font-family: 'Inter', sans-serif !important; */
  font-size: 1rem;
  color: #a0aec0;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
/* p{word-break:break-word} */
/*Font Family*/
.font-secondary{
  font-weight: bold;
  text-transform: uppercase;
}
.animate {
  transition: all .2s ease-in-out !important;
}
.animateBanner {
  transition: all .4s ease-in-out !important;
}

/*Calcul*/
.h-screen-r{
  height: calc(100vh - 80px);
}
/*Background*/
.bg-header{
  background-color: #0f1113f8;
}
.bg-body{
  background-color: #0f1113;
}
.bg-primary{
  background-color: #5a67d8;
}
.callToAction-bg-indigo{
  background-image: url('../images/Background/Abstract_background.svg');
  background-repeat: no-repeat;
  background-position: top right;
}
  .callToAction-bg-gray{
  background-image: url('../images/Background/Abstract_background-gray.svg');
  background-repeat: no-repeat;
  background-position: top right;
}
  .callToAction-bg-blue{
  background-image: url('../images/Background/Abstract_background-blue.svg');
  background-repeat: no-repeat;
  background-position: top right;
}

/*color*/
.primary-color{
  color: #667eea;
}
.title-color{
  color: #cbd5e0;
}
.gradient-banner-home{
  background: linear-gradient(#0f1113ca, #3e3f7000 , #0f11134d);
}
.primary-bg-color{
  color: #667eea;
}



/*pattern*/
.pattern1{
  z-index: -1;
  background-color: #0f1113;
  background-image: url("data:image/svg+xml,%3Csvg width='74' height='74' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%235a67d8' fill-opacity='0.06'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.pattern2{
  background-color: #0f1113;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23252525' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}
.pattern3{
  background-color: #0f1113;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%2315171a' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

/*border style*/
.border-t-3{
  border-top-width: 3px;
}
/*Link*/
/* border-b-2 border-indigo-600 */
.active{
  border-bottom: 2px solid #667eea;
}

/*images*/
.img-fit-container {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*Hover*/
.hover-scale img {
  transition: all .2s ease-in-out !important;
  transform: scale(1) !important;
  box-shadow: none;
}
.hover-scale img:hover {
  transition: all .2s ease-in-out !important;
  transform: scale(1.2) !important;
  box-shadow: none;
}

.hover-scale-portfolio-project img{
  transition: all .25s ease-in-out !important;
  transform: scale(1) !important;
  box-shadow: none;
}
.hover-scale-portfolio-project:hover img{
  transition: all .25s ease-in-out !important;
  transform: scale(1.2) !important;
  box-shadow: none;
}
.hover-scale-portfolio-project .mask{
  opacity: 0%;
  transition: all .25s ease-in-out !important;
}

.hover-scale-portfolio-project .stackProject{
  transform: scale(0.95) !important;
  top: 35%;
  opacity: 0%;
  transition: all .15s ease-in-out !important;
}
.hover-scale-portfolio-project:hover .stackProject{
  transform: scale(1) !important;
  top: 45%;
  opacity: 90%;
  transition: all .45s ease-in-out !important;
}

.hover-scale-portfolio-project .stackProjectGrid{
  transform: scale(0.95) !important;
  top: 27%;
  opacity: 0%;
  transition: all .15s ease-in-out !important;
}
.hover-scale-portfolio-project:hover .stackProjectGrid{
  transform: scale(1) !important;
  top: 37%;
  opacity: 90%;
  transition: all .25s ease-in-out !important;
}

.hover-scale-portfolio-project:hover  .mask, .hover-scale-portfolio-project:hover .stackProject{
  opacity: 90%;
  transition: all .25s ease-in-out !important;
}


.hover-ride-top {
  transition: all .2s ease-in-out !important;
}
.hover-ride-top:hover {
  transform: translateY(-15px);
  transition: all .2s ease-in-out !important;
}
.hover-ride-button-top {
  transition: all .2s ease-in-out !important;
}
.hover-ride-button-top:hover {
  transform: translateY(-5px);
  transition: all .2s ease-in-out !important;
}

/*Label*/
.labelSmall{
  display:inline-block;
  margin: 0.15rem;
  padding: 0.10rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.70rem;
}

:focus { outline: none; }
button:disabled,button[disabled]{
  background-color: #5e5d66;
  color: #1c1b25;
  cursor: not-allowed;
}
button:disabled:hover,button[disabled]:hover{
  background-color: #5e5d66;
}

button.disabledLight:disabled{
  background-color: #d4d3e2;
  color: #797979;
  cursor: not-allowed;
}
button.disabledLight:disabled:hover{
  background-color: #d4d3e2;
}

/*Style check box*/
[type="checkbox"], label.check { cursor: pointer;}

/*PlaceHolder*/
input.light::placeholder, textarea.light::placeholder {
    color: #535960;
}

/*delete style*/
input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
}

[type="checkbox"], [type="radio"] {
	box-sizing: border-box;
	padding: 0;
}

/*new style box*/
.form-checkbox {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	display: inline-block;
	vertical-align: middle;
	background-origin: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	flex-shrink: 0;
	height: 1rem;
	width: 1rem;
	color: #3f83f8;
	background-color: #fff;
	border-color: #d2d6dc;
	border-width: 1px;
	border-radius: .25rem;
}

/*new Style checked*/
.form-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z'/%3E%3C/svg%3E");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
}


.hover-animate{
  opacity: 0.5;
  transform: scale(1);
  transition: all .2s ease-in-out !important;
  transition: opacity .6s;
}
.hover-animate:hover{
  transform: scale(1.1);
  opacity: 1;
  transition: all .2s ease-in-out !important;
  transition: opacity .6s;
}
